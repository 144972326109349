<template>
  <div>
    <MobileItemView v-if="method === methodOnline" />
    <OtpInput v-else :maxLength="6" triggerOnMaxLength digitsOnly />
  </div>
</template>

<script>
  import MobileItemView from '../form-controls/MobileItemView.vue';
  import OtpInput from '../form-controls/OtpInput.vue';
  import {  VALIDATION_METHOD_ONLINE,
    VALIDATION_METHOD_OFFLINE
  } from '../../config';
  
  export default {
    components: {
      MobileItemView,
      OtpInput
    },
    props: {
      method: {
        type: String,
        default: VALIDATION_METHOD_ONLINE
      }
    },
    data() {
      return {
        methodOnline: VALIDATION_METHOD_ONLINE,
        methodOffline: VALIDATION_METHOD_OFFLINE
      }
    }
  }
</script>